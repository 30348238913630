<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="edit-profile">
        <div class="row">
          <div class="col-xl-4">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title mb-0">Settings</h4>
                <div class="card-options"><a class="card-options-collapse" href="#" data-toggle="card-collapse"><i
                      class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="#"
                    data-toggle="card-remove"><i class="fe fe-x"></i></a></div>
              </div>
              <div class="card-body">

                <div class="row mb-2">
                  <!-- <div class="col-auto"><img class="img-70 rounded-circle" alt="" src="@/assets/images/user/7.jpg">
                  </div> -->
                  <div class="col">
                    <h3 class="mb-1">MARK JECNO</h3>
                    <p class="mb-4">Admin</p>
                  </div>
                </div>

                <v-observer class="theme-form" ref="formUserInfo" tag="form"
                  @submit.prevent="fnApiSaveUserInfo( 'formUserInfo' )">

                  <div class="setup-content" id="step-1">
                    <div class="row ">
                      <div class="col-6">

                        <div class="form-group">

                          <label class="col-form-label text-capitalize"
                            v-text=" $t('finishInformation.firstName') "></label>

                          <v-validation rules="required|min:3" v-slot="{ errors }"
                            :name=" $t('finishInformation.firstName') ">

                            <input v-model="user.first_name " class="form-control" type="text"
                              :class=" { 'is-invalid':errors.length > 0 } ">

                            <div class="invalid-feedback" v-if="errors.length > 0">
                              {{ errors[0] }}
                            </div>

                          </v-validation>
                        </div>

                      </div>

                      <div class="col-6">
                        <div class="form-group">

                          <label class="col-form-label text-capitalize"
                            v-text=" $t('finishInformation.lastName') "></label>

                          <v-validation rules="required|min:3" v-slot="{ errors }"
                            :name=" $t('finishInformation.lastName') ">

                            <input v-model="user.last_name " class="form-control" type="text"
                              :class=" { 'is-invalid':errors.length > 0 } ">

                            <div class="invalid-feedback" v-if="errors.length > 0">
                              {{ errors[0] }}
                            </div>

                          </v-validation>
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="form-group">

                          <label class="col-form-label text-capitalize"
                            v-text=" $t('finishInformation.phone') "></label>

                          <v-validation rules="required|min:10" v-slot="{ errors }"
                            :name=" $t('finishInformation.phone') ">

                            <the-mask v-model="user.phone " class="form-control" type="text" placeholder="81-8080-8080"
                              mask="##-####-####" :class=" { 'is-invalid':errors.length > 0 } " />

                            <div class="invalid-feedback" v-if="errors.length > 0">
                              {{ errors[0] }}
                            </div>

                          </v-validation>
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="form-group">

                          <label class="col-form-label text-capitalize" v-text=" 'Country'  "></label>

                          <v-validation rules="required" v-slot="{ errors }" :name="  'Country'  ">

                            <select class="form-control" v-model="user.country"
                              :class=" { 'is-invalid':errors.length > 0 } ">
                              <option value="" default> Select one </option>
                              <option :value="country.code" v-for="  country in countriesList  " :key="country.code"
                                v-text="country.name">
                              </option>
                            </select>

                            <div class="invalid-feedback" v-if="errors.length > 0">
                              {{ errors[0] }}
                            </div>

                          </v-validation>
                        </div>
                      </div>

                    </div>
                  </div>
                  <input type="submit" :value=" $t('general.button.update') " class=" btn btn-primary btn-block ">
                </v-observer>

              </div>
            </div>
          </div>
          <div class="col-xl-8">



            <form class="card">
              <div class="card-header">
                <h4 class="card-title mb-0">Edit Profile</h4>
                <div class="card-options"><a class="card-options-collapse" href="#" data-toggle="card-collapse"><i
                      class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="#"
                    data-toggle="card-remove"><i class="fe fe-x"></i></a></div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8">

                    <v-observer class="theme-form mt-4" ref="fromSellerPayment" tag="form"
                      @submit.prevent="fnValidateInfo( 'fromSellerPayment' )">

                      <div class="row">

                        <div class="col-12 col-sm-5  col-md-5">
                          <div class="form-group">
                            <label class="control-label"> Card number
                            </label>
                            <v-validation rules="required|min:16" v-slot="{ errors }"
                              :name=" 'card' + $t('finishInformation.firstName') ">

                              <the-mask v-model="payment.card_number" class="form-control" type="text"
                                mask="####-####-####-####" placeholder="1234-1234-1234-4099"
                                :class=" { 'is-invalid':errors.length > 0 } " />

                              <div class="invalid-feedback" v-if="errors.length > 0">
                                {{ errors[0] }}
                              </div>

                            </v-validation>

                          </div>
                        </div>

                        <div class="col-7 col-sm-4  col-md-4">
                          <div class="form-group">
                            <label class="control-label"> First name
                            </label>

                            <v-validation rules="required|min:3" v-slot="{ errors }"
                              :name=" $t('finishInformation.firstName') ">

                              <input v-model="payment.first_name " class="form-control" type="text" placeholder="Robert"
                                :class=" { 'is-invalid':errors.length > 0 } ">

                              <div class="invalid-feedback" v-if="errors.length > 0">
                                {{ errors[0] }}
                              </div>

                            </v-validation>

                          </div>
                        </div>

                        <div class="col-5 col-sm-3  col-md-3">

                          <div class="form-group">
                            <label class="control-label">
                              Expiration date
                            </label>
                            <v-validation rules="required|min:3" v-slot="{ errors }"
                              :name=" 'exp' +  $t('finishInformation.firstName') ">
                              <the-mask v-model="payment.exp_date" class="form-control" type="text" mask="##/####"
                                placeholder="02/2021" :class=" { 'is-invalid':errors.length > 0 } " />
                              <div class="invalid-feedback" v-if="errors.length > 0">
                                {{ errors[0] }}
                              </div>
                            </v-validation>
                          </div>
                        </div>

                      </div>
                      <div class="form-group">
                        <label class="control-label"> Full name
                        </label>
                        <v-validation rules="required|min:3" v-slot="{ errors }"
                          :name=" 'full' +  $t('finishInformation.firstName') ">
                          <input v-model="payment.full_name  " class="form-control" type="text"
                            placeholder="Robert Rodriguez" :class=" { 'is-invalid':errors.length > 0 } ">

                          <div class="invalid-feedback" v-if="errors.length > 0">
                            {{ errors[0] }}
                          </div>
                        </v-validation>
                      </div>
                    </v-observer>

                  </div>
                </div>


              </div>
              <div class="card-footer text-right">
                <button class="btn btn-primary" type="submit">Update Profile</button>
              </div>
            </form>
          </div>

          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title mb-0">Add projects And Upload</h4>
                <div class="card-options"><a class="card-options-collapse" href="#" data-toggle="card-collapse"><i
                      class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="#"
                    data-toggle="card-remove"><i class="fe fe-x"></i></a></div>
              </div>
              <div class="table-responsive mb-0">
                <table class="table card-table table-vcenter text-nowrap">
                  <thead>
                    <tr>
                      <th>Project Name</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Price</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><a class="text-inherit" href="#">Untrammelled prevents </a></td>
                      <td>28 May 2018</td>
                      <td><span class="status-icon bg-success"></span> Completed</td>
                      <td>$56,908</td>
                      <td class="text-right"><a class="icon" href="javascript:void(0)"></a><a
                          class="btn btn-primary btn-sm" href="javascript:void(0)"><i class="fa fa-pencil-alt"></i>
                          Edit</a><a class="icon" href="javascript:void(0)"></a><a class="btn btn-transparent btn-sm"
                          href="javascript:void(0)"><i class="fa fa-link"></i> Update</a><a class="icon"
                          href="javascript:void(0)"></a><a class="btn btn-danger btn-sm" href="javascript:void(0)"><i
                            class="fa fa-trash"></i> Delete</a></td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
  export default {
    data() {
      return ({
        countriesList: [],
        user: {
          first_name: null,
          last_name: null,
          phone: null,
          country: null,
        },
        payment: {
          card_number: null,
          first_name: null,
          full_name: null,
          exp_date: null,
        },

      })
    },
    methods: {
      fnApiSaveUserInfo() {
      },
      fnGetCountries() {
        axios.get('').then(response => {
          this.countriesList = this.$lodash.clone(response.data);
        }).catch(error => {});
      },
    },
    mounted() {
      this.fnGetCountries();
    }
  }
</script>